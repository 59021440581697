module.exports = {
  LOG: 'LOG',
  FETCH: 'FETCH',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  UPDATE_OR_CREATE: 'UPDATE_OR_CREATE',
  DELETE: 'DELETE',
  ADD_M2M_TO_MODEL: 'ADD_M2M_TO_MODEL',
  REMOVE_RELATION_FROM_MODEL: 'REMOVE_RELATION_FROM_MODEL',
  ASSIGN_FOREIGNKEY: 'ASSIGN_FOREIGNKEY'
};
