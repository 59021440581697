import React from 'react';
import { MdMyLocation } from 'react-icons/md'
import styles from 'styles/snippets/UserLocationButton.module.scss';

class UserLocationButton extends React.Component {
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.onClick()
    }
  }
  
  render() {
    const {onClick} = this.props
    return (
      <div title="Sijainti" className={styles.button} onClick = {onClick} onKeyDown={this.handleKeyDown}>
          <div className={styles.icon}>
            <MdMyLocation size={32} color={'#ffffff'} />
          </div>
      </div>
    );
  }
}

export default UserLocationButton;
