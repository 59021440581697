import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import {IoMdPhotos} from 'react-icons/io'
import FloatingButton from '../snippets/FloatingButton';
import styles from 'styles/blocks/qrScanner-module.scss';

export default class QrScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      legacyMode: false,
      result: 'No result',
      error: false,
      facingMode: 'environment',
      delay: 500,
    };
  }

  handleScan = (result) => {
    if(result){
      this.setState({ result });
      window.location = result
    }
  }

  handleError = (err) => {
    console.error(err)
    this.setState({ legacyMode: true })
  }

  handleLoad = () => {
    this.setState({ loading: false });
  }

  handleImgSubmit = () => {
    this.refs.reader.openImageDialog()
  }

  toggleLegacymode = () => {
    this.refs.legacy.openImageDialog()
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h3>Error</h3>
        </div>
      )
    } else {
      return (
        <div style={{display: 'flex',justifyContent:'center', alignItems:'center', backgroundColor: 'black', height: window.innerHeight}}>
          {this.state.loading && (
            <div>
              <h3>Loading...</h3>
            </div>
          )}
          <div style={{display: this.state.loading ? 'none' : 'block'}}>
            <div style={{ width: window.innerWidth, maxWidth: '500px' }}>
              <QrReader
                onScan={this.handleScan}
                onError={this.handleError}
                onLoad={this.handleLoad}
                facingMode={this.state.facingMode}
                delay={this.state.delay}
                legacyMode={this.state.legacyMode}
                ref="reader"
              />
              <div className={styles.button}>
                <FloatingButton 
                  style={{
                    position: 'absolute', 
                    bottom: '90px', 
                    right: '5vw', 
                    padding: '1rem', 
                    width: '2rem', 
                    height: '2rem'
                  }} 
                  onClick={this.toggleLegacymode}>
                  <div style={{color:'white'}}>
                    <IoMdPhotos size={40} />
                  </div>
                </FloatingButton>
              </div>

            </div>
            <div style={{display: 'none'}}>
              <QrReader
                onScan={this.handleScan}
                onError={this.handleError}
                onLoad={this.handleLoad}
                delay={this.state.delay}
                legacyMode={true}
                ref="legacy"
              />
            </div>
            <h3>Decoded QR-Code: {this.state.result}</h3>
          </div>
        </div>
      );
    }
  }
}

