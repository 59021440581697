import React, { Component } from 'react'
import QrScanner from '../blocks/QrScanner';
import SwitchViewButton from '../snippets/SwitchViewButton';

export default class ReaderView extends Component {
  render() {  
    return(
        <div>
          <QrScanner />
          <SwitchViewButton />
        </div>
      )
    }
}

