import React, { Component } from 'react'
import FloatingButton from '../snippets/FloatingButton';
import FeedbackFields from '../snippets/FeedbackFields';

import styles from '../../styles/blocks/FeedbackForm.module.scss';

export default class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackForm: false
    }
  }

  showFeedbackForm() {
    this.setState({
      feedbackForm: true
    })
  }

  render() {
    return (
      <div className={styles.container}>
        { this.state.feedbackForm ?
          <FeedbackFields />
        :
          <FloatingButton style = {{color: '#e40016', cursor: 'pointer'}} onClick = {() => this.showFeedbackForm()} >
            <p className={styles.button}>Anna palautetta kohteesta</p>
          </FloatingButton>
        }
      </div>
    )
  }
}
