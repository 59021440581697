import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ErrorView from './ErrorView';
import AttractionView from './AttractionView';
import MapView from './MapView';
import ReaderView from './ReaderView'
import PlaceListView from './PlaceListView';
import InfoView from './InfoView';
import FooterView from './FooterView';

class BaseView extends React.Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact component={MapView}/>
          <Route path="/qr-reader" component={ReaderView}/>
          <Route path="/places" component ={PlaceListView}/>       
          <Route path="/attraction/:attractionCode([A-Z0-9]{7})" component={AttractionView}/>
          {/* 
            Redirect redirects urls that has 7 character after root url. 
            So avoid adding urs that has 7 character or put that Route before Redirect.
          */}
          <Redirect from="/:attractionCode([A-Z0-9]{7})" to={ {
            pathname: "/attraction/:attractionCode",
            state: {
              referrer: this.props.location.pathname,
              isQRCodeUsed: true
            }
          }}/>
          <Route path='/info' component={InfoView}/>
          <Route component={ErrorView} />
        </Switch>
        <FooterView />
      </div>
    );
  }
}

export default BaseView;
