import React, { useContext } from 'react';
import { ReduxContext } from 'App';
import AttractionList from '../snippets/AttractionList';

export default function PlaceList() {
  const  {places} = useContext(ReduxContext)

  const placesRender = places.map(place => {
    return <AttractionList key={place.id} placeData={place} />
  })
  return(
    <div>{placesRender}</div>
  )
}