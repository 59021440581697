import React, { Component } from 'react';
import { Provider, connect} from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Route, withRouter } from 'react-router-dom';
import history from './misc/history';
import {sendFeedback, fetchAttractions} from 'actions/attractionActions'

import BaseView from './components/views/BaseView';
import { getAttractionByCode, getPlaces } from './selectors/attractionSelectors';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router history={history}>
              <ReduxConnection>
                <Route path="/" component={BaseView} />
              </ReduxConnection>
            </Router>
          </PersistGate>
        </Provider>
      </React.Fragment>
    );
  }
}

export const ReduxContext = React.createContext();

function ReduxContextProvider(props) {
  return (
    <ReduxContext.Provider value={props}>
      {props.children}
    </ReduxContext.Provider>
  );
}

const ReduxConnection = withRouter(
  connect(
    (state, props) => {
      return {
        attraction: getAttractionByCode(state, props),
        places: getPlaces(state, props),
      };
    },
    { sendFeedback, fetchAttractions }
  )(ReduxContextProvider)
)


export default App;