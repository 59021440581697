import { persistStore } from 'redux-persist';

import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import api from './middleware/api';

import rootReducer from './reducers';

// Redux Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk, api];

function configureStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  return { persistor, store };
}

export const { persistor, store } = configureStore();
