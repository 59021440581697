import React from 'react';
import { Link } from 'react-router-dom';
import { FaListUl, FaMapMarkedAlt, FaTimesCircle } from 'react-icons/fa'
import styles from 'styles/snippets/SwitchViewButton.module.scss';

class SwitchViewButton extends React.Component {
  render() {
    if(window.location.pathname === "/") {
      return (
        <div title="Paikkaluettelo" className={styles.button}>
          <Link to="/places">
              <FaListUl size={32} color={'#e40016'} />
          </Link>
        </div>
      );
    } else if(window.location.pathname === "/places") {
      return (
        <div title="Kartta" className={styles.button}>
          <Link to="/">
              <FaMapMarkedAlt size={32} color={'#e40016'} />
          </Link>
        </div>
      );
    } else if(window.location.pathname === "/qr-reader") {
      return (
        <div title="Sulje" className={styles.closeButton}>
          <Link to="/">
              <FaTimesCircle size={32} color={'#FFFFFF'} />
          </Link>
        </div>
      );
    }

  }
}

export default SwitchViewButton;