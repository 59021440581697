import * as _ from '../middleware/constants';
import clone from 'clone';

export default function genericReducer(_action, Model, session) {
  let action = clone(_action);

  if (action.targetModelName && action.targetModelName !== Model.modelName) {
    return undefined;
  }

  if (action.parser) {
    action = action.parser(action, Model);
  }

  const payload = action.payload;

  if (!payload) {
    return undefined;
  }

  switch (action.type) {
    case _.LOG:
      break;

    case _.FETCH:
      payload.forEach(obj => {
        if (!Model.idExists(obj.id)) {
          Model.create(obj);
        }
      });
      break;

    case _.CREATE:
      if (Model.idExists('form')) {
        Model.withId('form').delete();
      }
      Model.create(payload);
      break;

    case _.UPDATE:
      Model.withId(payload.id).update(payload);
      break;

    case _.UPDATE_OR_CREATE:
      if (Model.idExists(payload.id)) {
        Model.withId(payload.id).update(payload);
      } else {
        Model.create(payload);
      }
      break;

    case _.REMOVE:
      const object = Model.withId(payload);
      object.delete();
      break;

    default:
      return undefined;
  }

  return undefined;
}
