import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import styles from 'styles/snippets/AttractionListItem.module.scss'

export default class AttractionListItem extends Component {
  render() {
    const {attractionData} = this.props
    return (
      <div className={styles.container}>
        <Link to={'/attraction/' + attractionData.qrcode}>
          <p className={styles.text}>
            {attractionData.name}
          </p>
        </Link>
      </div>
    )
  }
}
