import { store } from 'store';
import { orm } from 'models';
import { bindActionCreators } from 'redux';
import { createSelector } from 'redux-orm';
import { matchPath } from 'react-router'

import * as attractionActionCreators from 'actions/attractionActions';

const actions = bindActionCreators(attractionActionCreators, store.dispatch);
const dbSelector = state => state.reducer;

export const getAttraction = createSelector(
  orm,
  dbSelector,
  (state, props) => props,
  (session, props) => {
    const id = props.match.params.attractionID;
    if (session.Attraction.idExists(id)) {
      let attraction = session.Attraction.get({
        id: id
      });
      return Object.assign({},attraction.ref);
    }
    actions.fetchAttraction(id);
    return {};
  }
);

export const getAttractionByCode = createSelector(
  orm,
  dbSelector,
  (state, props) => props,
  (session, props) => {   
    const match = matchPath(props.location.pathname, {
      path: '/attraction/:attractionCode',
      exact: true,
      strict: false
    });
    if(match) {
      const code = match.params.attractionCode;
      let attraction = session.Attraction.get({
        qrcode: code
      });
      if(attraction) {
        return Object.assign({},attraction.ref);
      }
    }
    return null;
  }
)

export const getPlaces = createSelector(
  orm,
  dbSelector,
  session => {
    const queryset = session.Place.all()
      .toModelArray()
      .map( place => {
        const attractions = place.attractions.toRefArray().map((a) => ({
          ...a
        }))
        return{ ...place.ref, attractions};
      });
    if (!queryset.length) {
      actions.fetchPlaces();
      actions.fetchAttractions();
    }
    return queryset;
  }
);