import React, { Component } from 'react'
import QRButton from '../snippets/QRButton';
import Map from '../blocks/Map';
import SwitchViewButton from '../snippets/SwitchViewButton';

export default class MapView extends Component {

  render() {
    return (
      <div>
        <Map/>
        <SwitchViewButton/>
        <QRButton />
      </div>
      )
  }
}
