import { API, GET, POST } from '../middleware/api';
import * as _ from '../middleware/constants';
import { API_URL } from '../config';
import Attraction from '../models/Attraction';
import Place from '../models/Place'

export function fetchAttraction(id) {
  return {
    [API]: {
      targetModelName: Attraction.modelName,
      method: GET,
      url: `${API_URL}/api/attractions/${id}/`,
      types: ['REQUEST', _.UPDATE_OR_CREATE, 'FAIL']
    }
  };
}

export function fetchPlaces() {
  return {
    [API]: {
      targetModelName: Place.modelName,
      method: GET,
      url:`${API_URL}/api/places/`,
      types: ['REQUEST', _.FETCH, 'FAIL']
    }
  };
}

export function fetchAttractions() {
  return {
    [API]: {
      targetModelName: Attraction.modelName,
      method: GET,
      url: `${API_URL}/api/attractions/`,
      types: ['REQUEST', _.FETCH, 'FAIL']
    }
  };
}

export function sendFeedback(data){
  return {
    [API]: {
      method: POST,
      url: `${API_URL}/api/attractions/${data.id}/send_feedback/`,
      types: ['REQUEST', 'SUCCES', 'FAIL'],
      data: data
    }
  }
}