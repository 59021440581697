import React from 'react';
import { Link } from 'react-router-dom';
import { FaQrcode } from 'react-icons/fa'
import styles from 'styles/snippets/QRButton.module.scss';

class QRButton extends React.Component {
  render() {
    return (
      <div title="QR-koodilukija" className={styles.button}>
        <Link to="/qr-reader">
          <div className={styles.icon}>
            <FaQrcode size={32} color={'#ffffff'} />
          </div>
        </Link>
      </div>
    );
  }
}

export default QRButton;
