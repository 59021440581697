import React, { Component, useState, useEffect } from 'react'
import UserLocationButton from '../snippets/UserLocationButton';
/**
 * Creates a new marker and adds it to a group
 * @param {H.map.Group} group       The group holding the new marker
 * @param {H.geo.Point} coordinate  The location of the marker
 * @param {String} html             Data associated with the marker
 */
function addMarkerToGroup(group, marker) {
  // add custom data to the marker
  group.addObject(marker);
}

function addInfoBubble(group, data) {
  data.map((place) => (
    addMarkerToGroup(group, createMarker(place))
  ))
}

function createMarker(place) {
  var svgMarkup = '<svg width="48" height="48" xmlns="http://www.w3.org/2000/svg">' +
  '<circle stroke="#e40016" fill="white" cx="24" cy="24" r="22" stroke-width="2" />' +
  '<text x="24" y="32" font-size="20pt" font-family="Arial"  ' +
  'text-anchor="middle" fill="#e40016">${REPLACE}</text></svg>';
  var markerIcon = new window.H.map.Icon(svgMarkup.replace('${REPLACE}', place.attractions.length))

  var marker = new window.H.map.Marker({lat: place.latitude, lng: place.longitude}, {icon: markerIcon});
  marker.setData(createInfoBubble(place))

  return marker
}

function createInfoBubble(place) {
  let htmlStr = '<div style="padding: 1rem; line-height: 1.5rem; letter-spacing: 0.12rem; word-spacing: 0.16rem;">' + place.name

  for (var attraction in place.attractions) {
    htmlStr += '<p style="margin-top: 1rem; margin-bottom: 1rem; font-size: 1.2rem; line-height: 1.5rem; letter-spacing: 0.12rem; word-spacing: 0.16rem;"><a style="color: #F50018; text-decoration: none" href="/attraction/' + place.attractions[attraction].qrcode + '">' + place.attractions[attraction].name +'</p>'
  }

  htmlStr += '</div>'
  return htmlStr
}



export const UserLocation = (props) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [marker, setMarker] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const geoSettings = {
    highAccuracy: true
  }
  let watcher = undefined

  const onChange = ({coords, timestamp}) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
      accuracy: coords.accuracy,
      timestamp,
    });
  };

  const onError = (error) => {
    setError(error.message);
  };

  useEffect(() => {
    if (!position) {
      return;
    }
    const {latitude, longitude} = position;
    if(marker) {
      marker.setGeometry({lat: latitude, lng: longitude})
    } else if(props.map) {
      createUserMarker(props.map, latitude, longitude)
    }
  }, [position, enabled, props.map]);

  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }
    if(!enabled) {
      geo.clearWatch(watcher)
      return;
    }
    watcher = geo.watchPosition(onChange, onError, geoSettings);
    return () => geo.clearWatch(watcher);
  }, [enabled]);

  useEffect(() => {
    if (!window.localStorage) {
      return;
    }
    if (localStorage.getItem('geoLocationEnabled')) {
      setEnabled(JSON.parse(localStorage.getItem('geoLocationEnabled')));
    }
  }, []);
  
  const createUserMarker = (map, latitude, longitude) => {
    if(marker || latitude === undefined || longitude === undefined) {
      return;
    }
    var svgMarkup = '<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">' +
    '<circle stroke="white" fill="#34b4eb" cx="12" cy="12" r="11" stroke-width="2" /></svg>';
    var markerIcon = new window.H.map.Icon(svgMarkup)
    var newMarker = new window.H.map.Marker({lat: latitude, lng: longitude}, {icon: markerIcon})
    setMarker(newMarker);
    map.addObject(newMarker)
  }


  function moveToLocation(map) {
    if(!map) {
      return;
    }
    if(!enabled) {
      navigator.geolocation&&navigator.geolocation.getCurrentPosition(position => {
        const {latitude, longitude} = position.coords
        createUserMarker(map, latitude, longitude)        
        map.setZoom(14)
        map.setCenter( {lat: latitude, lng: longitude}, true) 
      })
      setEnabled(true)
      window.localStorage && localStorage.setItem('geoLocationEnabled', 'true')
    } else if(position.latitude){
      const {latitude, longitude} = position
      map.setZoom(14)
      map.setCenter( {lat: latitude, lng: longitude}, true) 
    }
  }
  return (
    <UserLocationButton onClick ={() => moveToLocation(props.map)}/>
  );
};


export default class HereMap extends Component {
  constructor(props) {
      super(props);

      this.platform = null;
      this.map = null;
      this.group = new window.H.map.Group();
      this.userLocationGroup = new window.H.map.Group()
      this.state = {
          app_id: props.app_id,
          app_code: props.app_code,
          useHTTPS: true,
          center: {
              lat: props.lat,
              lng: props.lng,
          },
          zoom: props.zoom,
          theme: props.theme,
          style: props.style,
      }
  }

  componentDidMount() {
      this.platform = new window.H.service.Platform(this.state);

      var layer = this.platform.createDefaultLayers();
      var container = document.getElementById('here-map');

      var map = new window.H.Map(container, layer.normal.map, {
          center: this.state.center,
          zoom: this.state.zoom,
        });
      this.map = map;

      var events = new window.H.mapevents.MapEvents(map);
      var behavior = new window.H.mapevents.Behavior(events);
      var ui = new window.H.ui.UI.createDefault(map, layer)
      //Map UI
      var mapSettings = ui.getControl('mapsettings');
      var zoom = ui.getControl('zoom');
      // var scalebar = ui.getControl('scalebar');

      mapSettings.setAlignment('top-left');
      zoom.setAlignment('top-left');
      // scalebar.setAlignment('left-middle');

      map.addObject(this.group);

      // add 'tap' event listener, that opens info bubble, to the group
      this.group.addEventListener('tap', function (evt) {
        // event target is the marker itself, group is a parent event target
        // for all objects that it contains
        var bubble =  new window.H.ui.InfoBubble(evt.target.getPosition(), {
          // read custom data
          content: evt.target.getData()
        });
        // show info bubble
        ui.addBubble(bubble);
      }, false);

      map.addObject(this.userLocationGroup);
      this.forceUpdate()
  } 

  render() {
    addInfoBubble(this.group, this.props.markerDatas);
    
    return (
      <div>
        <div id="here-map" style={{width: '100%', height: window.innerHeight - 50, background: 'grey' }} />
        <UserLocation map = {this.map}/>
      </div>

      );
  }
}
