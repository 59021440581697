import { attr, fk } from 'redux-orm';
import BaseModel from './BaseModel';

class Attraction extends BaseModel {
  toString() {
    return `Attraction: ${this.id}`;
  }
}

Attraction.modelName = 'Attraction';

Attraction.fields = {
  id: attr(),
  name: attr(),
  description: attr(),
  video_url: attr(),
  place: fk('Place', 'attractions'),
  qrcode: attr()
};

export default Attraction;