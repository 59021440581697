import React from 'react';
import { Link } from 'react-router-dom';
import styles from 'styles/views/FooterView.module.scss';


export default function FooterView() {
  
  return (
    <div className={styles.container}>
      <div className={styles.linkContainer}>
        {
          window.location.pathname !== "/info" ?
          <Link to="/info" title="Tietoja sovelluksesta" style={{color: '#e40016', cursor: 'pointer', textDecoration: 'none'}}>
            <p>Tietoja sovelluksesta</p>
          </Link>
          : 
          <Link to="/" title="Etusivulle" style={{color: ' #e40016', cursor: 'pointer', textDecoration: 'none'}}>
            <p>Etusivulle</p>
          </Link>
        }
      </div>          
    </div>
  ); 
}