import React from 'react';
import styles from 'styles/views/InfoView.module.scss';


export default function InfoView() {
  
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <h2>Tietoja sovelluksesta</h2>
        <p>
        Janakkalan Oma Liikunta -palvelussa voit tarkastella missä Janakkalan omatoimiseen liikuntaan tarkoitetut liikuntapaikat ja -laitteet sijaitsevat. Palvelun avulla voit katsoa laitteiden käyttöön opastavia ohjevideoita sekä jättää palautetta liikuntapaikoista ja -laitteista.
        </p>
        <h2>Saavutettavuusseloste</h2>
        <p>Janakkalan kaupunki pyrkii takaamaan Janakkalan Oma Liikunta 
          -palvelun saavutettavuuden Lain digitaalisten palvelujen tarjoamisesta 306/2019 mukaisesti.
        </p>
        <p>Tämä saavutettavuusseloste koskee Oma Liikunta -palvelua, joka sijaitsee osoitteessa 
          omaliikunta.janakkala.fi. 
        </p>
        <p>Tämä palvelu täyttää Lain digitaalisten palveluiden tarjoamisesta 
          306/2019 vaatimukset kaikilta osin. Oma Liikunta -palvelu on julkaistu 20.6.2019.
        </p>
        <p>Lisätietoja tämän palvelun saavutettavuudesta antaa Heidi Lampsijärvi, heidi.lampsijarvi@janakkala.fi
        </p>
        <h2>Tietojen kerääminen</h2>
        <p>
        Janakkalan Oma Liikunta käyttää Google Analytics -palvelua, jotta voisimme paremmin seurata kuinka Oma Liikunta -palvelua käytetään. Palvelusta saatuja tietoja ainoastaan yleistettyjen käyttötilastojen tuottamiseen. Näitä tilastoja käytetään myös palvelun kehitystyön tukemiseen. Google Analytics -palvelun tietosuojakäytännön voit käydä lukemassa osoitteessa:
        <a href="https://policies.google.com/privacy"> https://policies.google.com/privacy</a>
        </p>
      </div>
    </div>
  ); 
}