import axios from 'axios';
import cookies from 'js-cookie/src/js.cookie';

export const GET = 'get';
export const PUT = 'put';
export const POST = 'post';
export const DELETE = 'delete';

export const API = 'Call API';

export default store => next => action => {
  const callAPI = action[API];

  if (!callAPI) {
    return next(action);
  }

  // Using url
  if (!callAPI.url) {
    throw new Error('Missing URL');
  }

  // Using method
  if (
    !callAPI.method ||
    (callAPI.method !== GET &&
      callAPI.method !== POST &&
      callAPI.method !== PUT &&
      callAPI.method !== DELETE)
  ) {
    throw new Error('Missing HTTP method');
  }

  // Using three types: request, success and fail
  if (!callAPI.types || callAPI.types.length !== 3) {
    throw new Error('Invalid action types');
  }

  // Data merge
  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[API];
    return finalAction;
  };

  const [requestType, successType, failType] = callAPI.types;

  // Dispatch action
  const requestAction = actionWith({
    type: requestType,
    targetModelName: callAPI.targetModelName
  });
  next(requestAction);

  // The actual HTTP request
  return axios({
    url: callAPI.url,
    method: callAPI.method,
    data: callAPI.data,
    params: callAPI.params,
    withCredentials: true,
    headers: { 'X-CSRFToken': cookies.get('csrftoken') }
  })
    .then(response => response.data)
    .then(data => {
      next(
        actionWith({
          type: successType,
          targetModelName: callAPI.targetModelName,
          meta: callAPI.meta,
          parser: callAPI.parser,
          payload: data
        })
      );
    })
    .catch(error => {
      if (!error.response) {
        console.error('Dispatch failed: ', error);
      } else {
        next(
          actionWith({
            type: failType,
            status: error.response.status,
            payload: error.response.data
          })
        );
      }
    });
}