import React, { Component } from 'react'
import YouTube from 'react-youtube';
import styles from 'styles/blocks/YouTubePlayer.module.scss';

export default class YouTubePlayer extends Component {
  pickUpVideoId = (url) => {
    var ID = '';
    url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    
    return ID;
  }

  render() {
    const opts = {
      width: '100%',
      playerVars: {
        autoplay: 0
      }
    };
    const {videoUrl} = this.props
    
    return (
      <div className={styles.container}>
        <YouTube
        videoId={this.pickUpVideoId(videoUrl)}
        opts={opts}
        onReady={this._onReady}
        />
      </div>
    )
  }
}
