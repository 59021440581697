import { routerReducer } from 'react-router-redux';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from 'redux-orm';
import { orm } from '../models';

const reducer = createReducer(orm);

const config = {
  key: 'root',
  storage: storage,
  transforms: [],
  whitelist: [],
  debug: false
};

export default persistCombineReducers(config, {
  reducer,
  routing: routerReducer
});
