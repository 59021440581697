import React, { useState, useContext } from 'react'

import styles from '../../styles/snippets/FeedbackFields.module.scss';
import { ReduxContext } from 'App';

export default function FeedbackFields() {
  const [form, setForm] = useState({
    feedback: '',
    name: '',
    email: '',
    phone: ''
  })
  const {sendFeedback, attraction} = useContext(ReduxContext)
  const [sendClicked, setSendClicked] = useState(false);

  function handleChange(event)  {
    const target = event.target
    setForm({...form, ...{
      [target.name]: target.value
    }})
  }

  function onSendClicked() {
    setSendClicked(true)
    sendFeedback({...form, id: attraction.id})
  }

  return (
    <div>
      { !sendClicked ?
        <div className={styles.container}>
          <p>Jos haluat, että olemme yhteydessä sinuun, jätäthän palautteen lisäksi meille myös yhteystietosi.</p>
          <div className={styles.field}>
            <label>*Palautteesi:</label>
            <textarea name='feedback' type="text" rows="7" required value={form.feedback} onChange={handleChange}/>
          </div>
          <div className={styles.field}>
            <label>Nimi:</label>
            <input name='name' type="text" value={form.name} onChange={handleChange}/>
          </div>
          <div className={styles.field}>
            <label>Sähköposti:</label>
            <input name='email' type="email" value={form.email} onChange={handleChange}/>
          </div>
          <div className={styles.field}>
            <label>Puhelinnumero:</label>
            <input name='phone' type="number" value={form.phone} onChange={handleChange}/>
          </div>
          <button onClick={()=>onSendClicked()} disabled = {form.feedback?false:true}>Lähetä</button>
        </div>
      : <div className={styles.container}>
          <p>Kiitos palautteesta!</p>
        </div>
      }
    </div>
  );
  
}