import React, { Component } from 'react'
import styles from 'styles/views/ErrorView.module.scss'

export default class ErrorView extends Component {

  render() {
    document.title = "Sivua ei löytynyt"
    return (
      <p className={styles.text}>Sivua ei löytynyt</p>
    )
  }
}
