import React, { useContext, useEffect } from 'react';
import FeedbackForm from '../blocks/FeedbackForm';
import AttractionDetail from '../blocks/AttractionDetail';
import YouTubePlayer from '../blocks/YouTubePlayer';
import styles from 'styles/views/AttractionView.module.scss';
import { ReduxContext } from 'App'

export default function AttractionView(props) {
  const {attraction, fetchAttractions} = useContext(ReduxContext)
  const isQRCodeUsed = !!props.location.state && props.location.state.isQRCodeUsed
  useEffect(() => {
    fetchAttractions();
  }, [])

  useEffect(() => {
    if(attraction && window.gtag && isQRCodeUsed) {
      window.gtag('event',"qr-used", {
        'event_category': "qr-used",
        'event_label': attraction.name + " (" + attraction.qrcode + ")",
      });

    }
  }, [attraction])

  if(!attraction) {
    return false
  }
  document.title = attraction.name

  return (
    <div className={styles.container}>
      {
        attraction.video_url && 
          <YouTubePlayer videoUrl={attraction.video_url}/>
      }
      <div className={styles.flexcontainer}>
        <div className={styles.attraction}>
          <AttractionDetail attraction={attraction} />
        </div>
        <div className={styles.feedback}>
          <FeedbackForm />
        </div>
      </div>
    </div>
  ); 
}