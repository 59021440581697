import React, { useContext } from 'react'
import HereMap from '../snippets/HereMap';
import { HERE_MAP_ID, HERE_MAP_CODE } from 'config'
import { ReduxContext } from 'App';


export default function Map() {
  const {places} = useContext(ReduxContext)
  return (
    <div>
      <HereMap
        app_id={HERE_MAP_ID}
        app_code={HERE_MAP_CODE}
        lat="60.9201"
        lng="24.6460"
        zoom="14"
        markerDatas={places}
      />  
    </div>
  )
}