import React, { Component } from 'react'

export default class FloatingButton extends Component {
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.onClick()
    }
  }

  render() {
    const {onClick, children, style} = this.props
    return (
      <div style = {style} onClick={onClick} tabIndex="0" onKeyDown={this.handleKeyDown}>
        {children}
      </div>
    )
  }
}
