import { attr } from 'redux-orm';
import BaseModel from './BaseModel';

class Place extends BaseModel {
  toString() {
    return `Place: ${this.id}`;
  }
}
Place.modelName = 'Place';

Place.fields = {
  id: attr(),
  name: attr(),
  address: attr(),
  description: attr(),
  latitude: attr(),
  longtude: attr(),
};

export default Place;
