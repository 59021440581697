import { Model } from 'redux-orm';
import genericReducer from '../reducers/genericReducer';

class BaseModel extends Model {
  static reducer = genericReducer;

  toString() {
    return `${this.modelName || 'Model'}: ${this.Id}`;
  }
}

BaseModel.getPaginated = function(
  page = 1,
  paginateBy = 10,
  queryset = this.all().toRefArray()
) {
  const pageIndex = page - 1;
  const startIndex = pageIndex * paginateBy;
  const endIndex = pageIndex * paginateBy + paginateBy;
  return {
    results: queryset.slice(startIndex, endIndex),
    page: page,
    count: this.count()
  };
};

BaseModel.getRelatedField = function(relatedClass) {
  const relatedField = Object.values(BaseModel.fields).filter(field => {
    return field.toModelName !== relatedClass;
  })[0];
  return relatedField['relatedName'];
};

export default BaseModel;
