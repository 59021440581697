import React, { Component } from 'react'
import AttractionListItem from './AttractionListItem';
import styles from 'styles/snippets/AttractionList.module.scss'

export default class AttractionList extends Component {
  
  render() {
    const {placeData} = this.props
    const attractionsRender = placeData.attractions.map(attraction => {
      return <AttractionListItem key={attraction.id} attractionData={attraction} />
    })

    return (
      <div className={styles.container}>
      <p className={styles.placeName}>{placeData.name}</p>
        {attractionsRender}
      </div>
    )
  }
}