import React, { Component } from 'react'
import PlaceList from '../blocks/PlaceList';
import SwitchViewButton from '../snippets/SwitchViewButton';
import styles from 'styles/views/PlaceListView.module.scss'

export default class PlaceListView extends Component {
  componentDidMount() {
    document.title = "Paikkaluettelo"
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.flexcontainer}>
          <SwitchViewButton/>
        </div>
        <div>
          <PlaceList />
        </div>
      </div>

    )
  }
}
