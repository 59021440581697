import React, { Component } from 'react'
import styles from 'styles/blocks/AttractionDetail.module.scss';

export default class AttractionDetail extends Component {
  render() {
    const {attraction} = this.props;
    if(!attraction) {
      return false;
    }
    
    return (
      <div className={styles.container}>
        <p className={styles.header}>{attraction.name}</p>
        <p className={styles.description}>{attraction.description}</p>
      </div>
    );
  }
}
